import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Col, Row, Media } from "react-bootstrap"

import machineStatus from "../images/machine-status.png"
import totalCapacity from "../images/total-capacity.png"
import alarmStatus from "../images/alarms.webp"
import consumptionStatus from "../images/consumption-status.png"
import currentprogramStatus from "../images/current-program.png"
import overall from "../images/overall.png"


export default () => {
  return (
    <StaticQuery
      query={graphql`
      query FeaturesQuery {
        allFeaturesYaml {
          edges {
            node {
              id  
              description
              title
              imageAlt
              image {
                childImageSharp {
                  fixed (width: 100,  quality:100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      `}
      render={data => {

        const FeatureTitle = styled.h4`
        font-weight:bold;    
        font-size:1rem;
        margin:0;
        margin-bottom:1rem;
`

        const FeatureDesc = styled.h4`
font-weight:100;
font-size:1rem;
`
        const FeatureImage = styled(Img)`
margin-bottom:1rem;
margin-top:1rem
`
        const Column = styled(Col)`
text-align:-webkit-center;
margin-top:1rem;
margin-bottom:3rem;
align-self: center;
`
        const FeatureSubDesc = styled.p`
        font-size:.7rem;
`


        return (<Layout>
          <SEO title="Features"
            description="Proalcs Industrial new generation laundry solutions; coin laundromat, smart laundromat, programmatic laundromat, online laundry management." />
          <Container>
            <div className='prolacs-divider-title'>
              <br></br>
              <br></br>
              <h1>Features</h1>
            </div>
            <Row>
              {
                data.allFeaturesYaml.edges.map(({ node }) => (
                  <Column sm={4} key={node.id}>
                    <FeatureImage
                      fixed={node.image.childImageSharp.fixed}
                      alt={node.imageAlt}
                    />
                    <FeatureTitle>{node.title}</FeatureTitle>
                    <FeatureDesc> {node.description}</FeatureDesc>
                  </Column>
                ))
              }
            </Row>

            <div className='prolacs-divider-title'>
              <br></br>
              <br></br>
              <h1>More Features</h1>
            </div>
            <Row>
              <Col md>
                <Media>
                  <Media.Body>
                    <FeatureTitle>Machine Status</FeatureTitle>
                    <FeatureDesc>Current status of each machine.</FeatureDesc>
                    <FeatureSubDesc>Running, cancel, pause, idling etc.</FeatureSubDesc>
                  </Media.Body>
                  <img
                    className="ml-3"
                    src={machineStatus}
                    alt="Machine Status"
                  />
                </Media>
              </Col>
              <Col md>
                <Media>
                  <Media.Body>
                    <FeatureTitle>Total Capacity Utilization</FeatureTitle>
                    <FeatureDesc>See total capacity utilization in real time.</FeatureDesc>
                  </Media.Body>
                  <img
                    className="ml-3"
                    src={totalCapacity}
                    alt="Total Capacity Utilization"
                  />
                </Media>
              </Col>
            </Row>
            <Row>
              <Col md>
                <Media>
                  <Media.Body>
                    <FeatureTitle>Alarms</FeatureTitle>
                    <FeatureDesc style={{ fontSize: 'large' }}>50+ alarms to alert when required.</FeatureDesc>
                    <FeatureSubDesc>Emergency Stop, Water Fill Fail, Inverter Alarm, Power Fail etc.</FeatureSubDesc>
                  </Media.Body>
                  <img
                    className="ml-3"
                    src={alarmStatus}
                    alt="Alarms"
                  />
                </Media>
              </Col>
              <Col md>
                <Media>
                  <Media.Body>
                    <FeatureTitle>Consumption Status</FeatureTitle>
                    <FeatureDesc style={{ fontSize: 'large' }}>Measures consumption such as energy, water, steam and chemicals.</FeatureDesc>
                    <FeatureSubDesc>Machine, Laundry or Production Line</FeatureSubDesc>
                  </Media.Body>
                  <img
                    className="ml-3"
                    src={consumptionStatus}
                    alt="Consumption Status"
                  />
                </Media>
              </Col>
            </Row>
            <Row>
              <Col md>
                <Media>
                  <Media.Body>
                    <FeatureTitle>Current Program on Machine</FeatureTitle>
                    <FeatureDesc>Allows you to see the current program running on machines in real-time.</FeatureDesc>
                  </Media.Body>
                  <img
                    className="ml-3"
                    src={currentprogramStatus}
                    alt="Current Program on Machine"
                  />
                </Media>
              </Col>
              <Col md>
                <Media>
                  <Media.Body>
                    <FeatureTitle>Overall Equipment Effectiveness (OEE)</FeatureTitle>
                    <FeatureDesc>Instead of unnecessary purchases, it allows to increase the performance of the machines and equipment available.</FeatureDesc>
                  </Media.Body>
                  <img
                    className="ml-3"
                    src={overall}
                    alt="Overall Equipment Effectiveness (OEE)"
                  />
                </Media>
              </Col>
            </Row>
          </Container>
        </Layout >
        )
      }}
    />
  )
}
